
.center-contenido{
    height: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;        
    align-content: center;      
}

